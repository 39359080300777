import { graphql, useStaticQuery } from "gatsby";

const useLinkedinIconMini = () => {
  //var data = iconQuery();
  const data = useStaticQuery(graphql`
    query {
      iconGithub: file(
        relativePath: { eq: "assets/images/github-mark-64px.png" }
      ) {
        childImageSharp {
          fixed(width: 64, height: 64) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      iconGithubSmall: file(
        relativePath: { eq: "assets/images/github-mark-64px.png" }
      ) {
        childImageSharp {
          fixed(width: 32, height: 32) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      iconGithubMini: file(
        relativePath: { eq: "assets/images/github-mark-64px.png" }
      ) {
        childImageSharp {
          fixed(width: 24, height: 24) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      iconLinkedin: file(
        relativePath: { eq: "assets/images/icons8-linkedin-filled-72.png" }
      ) {
        childImageSharp {
          fixed(width: 72, height: 72) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      iconLinkedinSmall: file(
        relativePath: { eq: "assets/images/icons8-linkedin-filled-72.png" }
      ) {
        childImageSharp {
          fixed(width: 36, height: 36) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      iconLinkedinMini: file(
        relativePath: { eq: "assets/images/icons8-linkedin-filled-72.png" }
      ) {
        childImageSharp {
          fixed(width: 27, height: 27) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      iconTwitter: file(
        relativePath: { eq: "assets/images/icons8-twitter-96.png" }
      ) {
        childImageSharp {
          fixed(width: 80, height: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      iconTwitterSmall: file(
        relativePath: { eq: "assets/images/icons8-twitter-96.png" }
      ) {
        childImageSharp {
          fixed(width: 40, height: 40) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      iconTwitterMini: file(
        relativePath: { eq: "assets/images/icons8-twitter-96.png" }
      ) {
        childImageSharp {
          fixed(width: 30, height: 30) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return data.iconLinkedinMini.childImageSharp.fixed;
};

export default useLinkedinIconMini;
