import React from "react"
import Helmet from 'react-helmet';
import styled from "@emotion/styled"
import { Global, css } from "@emotion/core"
import {colors, heightNavbarScreenL, heightFooter } from "../styles/theme"
import Header from "./header"
import Footer from "./footer"
import useSiteMetadata from '../hooks/use-sitemetadata';


const Container = styled.div`
    // taking min height of navbar for now, need to do media q for better implementation
    min-height: calc(100vh - (${heightNavbarScreenL} + ${heightFooter} + 6rem)); 

    margin: 0 auto;
    width: 960px;
    max-width: 90%;
    // border: 1px solid red;
`;


const Layout = ({ children }) => {
    const { brand } = useSiteMetadata();
    return (
        <>
            <Helmet
                key="app-head"
                titleTemplate="%s · Santanu Paul"
                defaultTitle="Santanu Paul"
            >
                <html lang="en" />
                <link rel="preconnect" href="https://typekit.com" />
                <link rel="preconnect" href="https://use.typekit.net" />
                <link rel="preconnect" href="https://p.typekit.net" />

                <meta charSet="utf-8" />
                <meta name="viewport" content="initial-scale=1.0, width=device-width" />
                <title>{brand}</title>

                {/* Favicon stuff from favicon.io */}
                <meta name="apple-mobile-web-app-title" content="sntnupl.com" />
                <meta name="application-name" content="sntnupl.com" />
                <meta name="theme-color" content="#f0b429" />
                <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
                <link rel="manifest" href="/site.webmanifest" />
                {/* <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#c800ec" /> */}

                {/* Hosted webfonts because I can’t self-host adobe fonts 😭 */}
                <link rel="stylesheet" href="https://use.typekit.net/kpd0djt.css" />
            </Helmet>
            <Global
                styles={css`
                    html {
                        box-sizing: border-box;
                        background-color: ${colors.lightestGrey};
                        margin: 0;
                    }

                    *,
                    *:before,
                    *:after {
                        box-sizing: inherit;
                    }


                    html, 
                    body {
                        margin: 0;
                        /* remove margin for the main div that gatsby mounts into */
                        > div {
                            margin-top: 0;
                        }
                    }

                    a {
                        color: white;
                        text-decoration: none;
                    }
                `}
            />
            <Header brand={brand} />
            <Container>{children}</Container>
            <Footer />
        </>
    );
}

export default Layout;