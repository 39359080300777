import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core"
import styled from "@emotion/styled"
import {colors, heightNavbarScreenL, heightNavbarScreenM, heightNavbarScreenS } from "../styles/theme"


const HeaderContainer = styled.div`
    // border: 1px solid green;
    width: 100%;
    background-color: ${colors.darkestGrey};

    display: grid;
    grid-template-colums: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "col-brand col-empty col-links";

    @media (min-width: 790px) {
        height: ${heightNavbarScreenL};
    }

    @media (min-width: 640px) and (max-width: 789px) {
        height: ${heightNavbarScreenM};
    }

    @media (min-width: 320px) and (max-width: 639px) {
        height: ${heightNavbarScreenS};
        grid-template-colums: 1fr;
        grid-template-rows: 1fr 1fr;
        grid-template-areas: 
            "col-brand"
            "col-links";
    }
`;

const Brand = styled.h1`
    // border: 1px solid yellow;
    grid-area: col-brand;

    font-family: proxima-nova, sans-serif;
    font-weight: 700;
    font-style: normal;

    @media (min-width: 790px) {
        font-size: 4rem;
        align-self: center;
        margin: 0 auto 0 3rem;
    }

    @media (min-width: 640px) and (max-width: 789px) {
        font-size: 4rem;
        align-self: center;
        margin: 0 auto 0 3rem;
    }
    
    
    @media (min-width: 320px) and (max-width: 639px) {
        font-size: 3rem;
        align-self: center;
        justify-self: center;
        margin: 0;
    }
`;


const HeaderLinks = styled.div`
    grid-area: col-links;
    // border: 1px solid red;

    display: grid;

    @media (min-width: 640px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        grid-template-areas: 
            "link-empty link-empty link-blog link-contact";
        margin-right: 3rem;
    }

    @media (min-width: 320px) and (max-width: 639px) {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        grid-template-areas: 
            "link-blog link-contact";
        justify-self: center;
    }
`;

const HeaderLink = styled.div`
    // border: 1px solid white;

    @media (min-width: 640px) {
        justify-self: end;
        align-self: center;
        padding-right: 1rem;

        &:last-of-type {
            padding-right: 0;
        }
    }
    
    @media (min-width: 320px) and (max-width: 639px) {
        justify-self: center;
        align-self: center;
        margin-left: 1rem;
        margin-right: 1rem;
    }
`;

const NavLink = styled(Link)`
    font-family: proxima-nova, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 1.5rem;
    color: ${colors.lightestGrey};
    

    &.current-page {
        color: ${colors.gold};
    }

    &:hover {
        border-bottom: 4px solid ${colors.gold};
    }
`;

const Header = (props) => {
    return (
        <HeaderContainer>
            <Brand><Link to="/">{props.brand}</Link></Brand>
            <HeaderLinks>
                <HeaderLink css={css`
                    grid-area: link-blog;
                `}
                >
                    <NavLink to="/blog" activeClassName="current-page" partiallyActive={true}>Blog</NavLink>
                </HeaderLink>
                <HeaderLink css={css`
                    grid-area: link-contact;
                `}
                >
                    <NavLink to="/contact" activeClassName="current-page">Contact</NavLink>
                </HeaderLink>
            </HeaderLinks>
        </HeaderContainer>
    )
};

export default Header;