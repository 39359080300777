import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { css } from "@emotion/core";
import { GithubIconMini, LinkedInIconMini, TwitterIconMini } from "./icons"
import { colors, heightFooter, topMarginFooter} from "../styles/theme"

const Footer = styled.div`
    // https://bit.ly/2PipRGs

    width: 100%;
    background-color: ${colors.lightestGrey};

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: ${heightFooter};
    grid-template-areas: "f-copyright f-social f-links";
    margin-top: ${topMarginFooter};
`;

const Copyright = styled.h1`
    grid-area: f-copyright;
    align-self: center;
    justify-self: start;

    font-family: proxima-nova, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 1.25rem;
    color: ${colors.textLight};
    margin-left: 3rem;

    @media (min-width: 320px) and (max-width: 639px) {
        font-size: 0.75rem;
    }
`;

const SocialLinks = styled.div`
    grid-area: f-social;
    align-self: center;
    justify-self: center;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
`;

const FooterLinks = styled.div`
    grid-area: f-links;
    align-self: center;

    display: grid;

    @media (min-width: 640px) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr;
        grid-auto-flow: column;
        grid-template-areas: "l-empty l-empty l-home l-blog l-contact";
        align-items: center;
        
        margin-right: 3rem;
    }
    
    
    @media (min-width: 320px) and (max-width: 639px) {
        justify-self: center;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        grid-template-areas: 
            "l-home"
            "l-blog"
            "l-contact";
        grid-auto-flow: row;
        margin-right: 2rem;
    }
`;

const FooterLink = styled(Link)`
    font-family: proxima-nova, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 1rem;
    color: ${colors.textLight};
    margin: 0 auto;
    align-self: end;
    justify-self: center;

    @media (min-width: 640px) {
        padding-right: 0.5rem;

        &:last-of-type {
            padding-right: 0;
        }
    }

    &:hover {
        border-bottom: 4px solid ${colors.gold};
    }
`;



export default () => (
  <Footer>
    <Copyright>All content © Santanu Paul</Copyright>
    <SocialLinks>
        <GithubIconMini />
        <LinkedInIconMini />
        <TwitterIconMini />
    </SocialLinks>
    <FooterLinks>
        <FooterLink 
            to="/"
            css={css`
                grid-area: l-home
            `}
        >
            Home
        </FooterLink>
        <FooterLink 
            to="/blog/"
            css={css`
                grid-area: l-blog
            `}
        >
            Blog</FooterLink>
        <FooterLink 
            to="/contact/"
            css={css`
                grid-area: l-contact
            `}
        >
            Contact
        </FooterLink>
    </FooterLinks>
  </Footer>
);
