import React from "react"
import Img from "gatsby-image"
import { css } from "@emotion/core"
import useGithubIcon from "../hooks/use-githubicon"
import useGithubIconMini from "../hooks/use-githubicon-mini"
import useLinkedinIcon from "../hooks/use-linkedinicon"
import useLinkedinIconMini from "../hooks/use-linkedinicon-mini"
import useTwitterIcon from "../hooks/use-twittericon"
import useTwitterIconMini from "../hooks/use-twittericon-mini"
import { breakpointsMapMax as mediaQuery } from "../styles/theme"

export const EmailIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    width="64"
    height="64"
    css={css`
      margin-top: 12px;
      margin-right: 0.75rem;
      ${mediaQuery[1]} {
        display: none;
      }
    `}
  >
    <path d="M18 2a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4c0-1.1.9-2 2-2h16zm-4.37 9.1L20 16v-2l-5.12-3.9L20 6V4l-10 8L0 4v2l5.12 4.1L0 14v2l6.37-4.9L10 14l3.63-2.9z" />
  </svg>
)

export const GithubIcon = ({ useSmall }) => {
  const icon = useGithubIcon(useSmall)

  return (
    <a
      href="https://github.com/sntnupl"
      alt="Github Profile: https://github.com/sntnupl"
    >
      <Img fixed={icon} />
    </a>
  )
}

export const GithubIconMini = () => {
  const icon = useGithubIconMini()

  return (
    <div
      css={css`
        margin-top: 2px;
      `}
    >
      <a
        href="https://github.com/sntnupl"
        alt="Github Profile: https://github.com/sntnupl"
      >
        <Img fixed={icon} />
      </a>
    </div>
  )
}

export const LinkedInIcon = ({ useSmall }) => {
  const icon = useLinkedinIcon(useSmall)

  return useSmall ? (
    <a
      href="https://linkedin.com/in/sntnupl"
      alt="LinkedIn Profile: https://linkedin.com/in/sntnupl"
      css={css`
        margin-top: -3px;
        margin-left: 2rem;
      `}
    >
      <Img fixed={icon} />
    </a>
  ) : (
    <a
      href="https://linkedin.com/in/sntnupl"
      alt="LinkedIn Profile: https://linkedin.com/in/sntnupl"
      css={css`
        margin-top: -5px;
        margin-left: 2rem;
      `}
    >
      <Img fixed={icon} />
    </a>
  )
}

export const LinkedInIconMini = () => {
  const icon = useLinkedinIconMini()

  return (
    <div
      css={css`
        margin-top: 1px;
      `}
    >
      <a
        href="https://linkedin.com/in/sntnupl"
        alt="LinkedIn Profile: https://linkedin.com/in/sntnupl"
      >
        <Img fixed={icon} />
      </a>
    </div>
  )
}

export const TwitterIcon = ({ useSmall }) => {
  const icon = useTwitterIcon(useSmall)

  return useSmall ? (
    <a
      href="https://twitter.com/sntnupl"
      alt="Twitter Profile: https://twitter.com/sntnupl"
      css={css`
        margin-top: -3px;
        margin-left: 2rem;
      `}
    >
      <Img fixed={icon} />
    </a>
  ) : (
    <a
      href="https://twitter.com/sntnupl"
      alt="Twitter Profile: https://twitter.com/sntnupl"
      css={css`
        margin-top: -10px;
        margin-left: 2rem;
      `}
    >
      <Img fixed={icon} />
    </a>
  )
}

export const TwitterIconMini = ({ useSmall }) => {
  const icon = useTwitterIconMini()

  return (
    <div
      css={css`
        margin-top: -1px;
      `}
    >
      <a
        href="https://twitter.com/sntnupl"
        alt="Twitter Profile: https://twitter.com/sntnupl"
      >
        <Img fixed={icon} />
      </a>
    </div>
  )
}
