export const colors = {
    lightestGrey1: `#d9e2ec`,
    gold: `#f0b429`,
    brown: `#8d2b0b`,
    black: `#272727`,
    darkestGrey: `#1e2227`,
    mediumGrey: `#707070`,
    backgroundGrey: `#685d71`,
    lightestGrey: `#e4e7eb`,
    textDark: `#102a43`,
    textLight: `#5e5e5e`,
};

export const heightNavbarScreenL = `6rem`;
export const heightNavbarScreenM = `10rem`;
export const heightNavbarScreenS = `8rem`;
export const heightFooter = `6rem`;
export const topMarginFooter = `4rem`;

export const breakpoints = [320, 640, 768, 1024, 1100];

export const breakpointsMapMin = breakpoints.map(p => `@media (min-width: ${p}px)`);

export const breakpointsMapMax = breakpoints.map(p => `@media (max-width: ${p}px)`);